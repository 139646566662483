// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"
import "trix"
import "@rails/actiontext"
import "youtube"
import '@hotwired/turbo-rails'
import '@popperjs/core'
import "bootstrap"

//require("service/questions")

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
//$(window).on('beforeunload', function(){ # for bug in firefox
 //   socket.close();
//});

import "shareon/dist/shareon.min"
// ниже строка - включение стимулуса
//import "controllers"

// этот код - включение pwa. Взят с https://onrails.blog/2019/01/08/easy-pwas-the-rails-way/
if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/service-worker.js', { scope: './' })
        .then(function(reg) {
            console.log('[Companion]', '========== Service worker registered from application.js ===========');
            console.log(reg);
        });
}

